import React, { useState } from 'react';

import { Button, Card, Input, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import auditTrailMap, { AuditTrailType } from './audit-trail-map';

import './AuditTrail.scss';

type AuditTrailModalProps = {
  setAuditTrail: React.Dispatch<React.SetStateAction<boolean>>;
  auditTrail: boolean;
  searchFields?: { id: string; value: string }[];
};

const AuditTrailModal = ({
  auditTrail,
  setAuditTrail,
  searchFields,
}: AuditTrailModalProps) => {
  const [auditTrailData, setAuditTrailData] = useState(auditTrailMap);
  const { Search } = Input;
  const { Option } = Select;
  const [searchField, setSearchField] = useState<string>('Select Field');

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setSearchField(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item: { id: string; value: string }) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );

  const handleSearch = (e: any) => {
    const encodedValue = encodeURIComponent(e.trim());

    const filteredData = auditTrailData.filter((item) => {
      return item[searchField].includes(encodedValue);
    });
    setAuditTrailData(filteredData);
  };

  const handleClear = (value: string) => {
    if (value === '') {
      setAuditTrailData(auditTrailMap);
      setSearchField('Select Field');
    }
  };

  const columns: ColumnsType<AuditTrailType> = [
    {
      title: 'Class (First 4 digit of Prod ID)',
      dataIndex: 'classId',
      key: 'classId',
    },
    {
      title: 'Product ID',
      key: 'productId',
      render: (record: AuditTrailType) => (
        <span className=" whitespace-nowrap">{record.productId}</span>
      ),
    },
    {
      title: 'Service ID',
      key: 'serviceId',

      render: (record: AuditTrailType) => (
        <span className=" whitespace-nowrap">{record.serviceId}</span>
      ),
    },
    {
      title: 'Service Description',
      key: 'serviceDescription',
      dataIndex: 'serviceDescription',
    },
    {
      title: 'Annual Rate',
      key: 'annualRate',
      dataIndex: 'annualRate',
    },
    {
      title: 'Coverage',
      key: 'coverage',
      dataIndex: 'coverage',
    },
    {
      title: 'Customer Number',
      key: 'customerNbr',
      dataIndex: 'customerNbr',
    },
    {
      title: 'Quote Number',
      key: 'quoteNbr',
      dataIndex: 'quoteNbr',
    },
    {
      title: 'Quote Number',
      key: 'quoteNbr',
      dataIndex: 'quoteNbr',
    },
    {
      title: 'Execution Status',
      key: 'executionStatus',
      dataIndex: 'executionStatus',
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      dataIndex: 'uploadedBy',
    },
    {
      title: 'Uploaded At',
      key: 'uploadedAt',
      render: (record: AuditTrailType) => (
        <span className=" whitespace-nowrap">{record.uploadedAt}</span>
      ),
    },
    {
      title: 'Execution Summary',
      key: 'executionSummary',
      dataIndex: 'executionSummary',
    },
  ];
  return (
    <Modal
      title="Audit Trail"
      open={auditTrail}
      onCancel={() => setAuditTrail(false)}
      destroyOnClose
      width="90%"
      footer={[
        <Button key="back" size="large" onClick={() => setAuditTrail(false)}>
          Close
        </Button>,
      ]}
    >
      <Card>
        <div className="search-bar">
          <Search
            addonBefore={selectBefore}
            placeholder="Search for Class, Product ID, Service ID, Coverage, Customer Number, Quote Number, Execution Status, Uploaded By, Uploaded At"
            size="large"
            allowClear
            onSearch={(e) => handleSearch(e)}
            onChange={(e) => handleClear(e.target.value)}
          />
        </div>
        <br />
        <Table
          columns={columns}
          rowKey={(record) => record.productId!}
          id="audit-trail"
          dataSource={auditTrailData}
          pagination={false}
        />
      </Card>
    </Modal>
  );
};

export default AuditTrailModal;
