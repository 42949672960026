import React, { useState } from 'react';

import { DeleteFilled, EditFilled } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
} from 'antd';
import { useQuery } from 'react-query';

import AuditTrailModal from './AuditTrailModal';
import { type CustomerMapType } from './customer-map';
import DataFilters from './DataFilters';
import CustomerMapService from '../../APIServices/CustomerMapService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ClearFilter from '../ClearFilter';

type CustomerMapTableProps = {
  searchFields?: { id: string; value: string }[];
  openNotificationWithIcon: any;
};

type ExportCSVProps = {
  fileName: string;
};

const ExportCSV = ({ fileName }: ExportCSVProps) => {
  const axiosPrivate = useAxiosPrivate();
  const downloadCSV = async () => {
    try {
      const response = await axiosPrivate.get('/customer-map/export');
      const csvData = await response.data;
      const csvURL = URL.createObjectURL(
        new Blob([csvData], { type: 'text/csv' })
      );
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <Button type="primary" className="px-4" onClick={downloadCSV}>
      Export
    </Button>
  );
};

const CardActions = ({
  filteredData,
}: /* handleAdd,
  entryCount,
  handleSyncConfirm, */
any) => {
  return (
    <div className="flex gap-3 justify-between">
      {/* <Button
        type="primary"
        className="flex items-center justify-between gap-1 px-4"
        onClick={() => handleAdd()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <span>Add</span>
      </Button> */}
      <ExportCSV fileName={`${new Date().getTime()}_customer_map`} />
      {/* <Popconfirm
        placement="topRight"
        disabled={entryCount === 0}
        title={
          <span>
            There is unsynced data. Please confirm by pressing&nbsp;
            <br />
            <b>YES</b> or <b>NO</b> to sync on platform?
          </span>
        }
        onConfirm={() => handleSyncConfirm()}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          className={entryCount === 0 ? '' : 'px-4'}
          disabled={entryCount === 0}
        >
          Sync {entryCount === 0 ? '' : `(${entryCount})`}
        </Button>
      </Popconfirm> */}
    </div>
  );
};

const CustomerMapTable = ({
  searchFields,
  openNotificationWithIcon,
}: CustomerMapTableProps) => {
  const [auditTrail, setAuditTrail] = useState(false);

  const [newRecord, setNewRecord] = useState<CustomerMapType>(null!);
  const [updateRecord, setUpdateRecord] = useState<CustomerMapType>(null!);

  const [entryCount, setEntryCount] = useState(0);

  const [searchField, setSearchField] = useState<string>('Select Field');
  const [searchValue, setSearchValue] = useState<string>('');
  // const [customerMapData, setCustomerMapData] = useState(customerMap);
  const { Search } = Input;
  const { Option } = Select;

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filters, setFilters] = useState('isdeleted=false');

  // fetch data
  const { data: customerMap, isLoading } = useQuery(
    ['contracts', page, pageSize, filters],
    () => CustomerMapService.getCustomerMap(page, pageSize, filters)
  );

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setSearchField(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item: { id: string; value: string }) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );

  const tableRefresh = () => {
    setPage(0);
    setPageSize(10);
  };

  const handleSearch = (e: any) => {
    tableRefresh();
    const encodedValue = encodeURIComponent(e.trim());
    setFilters((prevState) => `${prevState}&${searchField}=${encodedValue}`);
  };

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };
  /* const handleSearch = (e: any) => {
    const encodedValue = encodeURIComponent(e.trim());

    const filteredData = customerMap.filter((item) => {
      return item[searchField].includes(encodedValue);
    });
    setCustomerMapData(filteredData);
  };

  const handleClear = (value: string) => {
    if (value === '') {
      setCustomerMapData(customerMap);
      setSearchField('Select Field');
    }
  }; */

  /* const confirmDelete = (classId: string) => {
    const updatedData = customerMapData.filter(
      (item) => item.classId !== classId
    );
    setCustomerMapData(updatedData);
    setEntryCount(entryCount + 1);
    openNotificationWithIcon(
      'success',
      'Success',
      'Customer map entry successfully deleted'
    );
  }; */

  /* const handleAdd = () => {
    const newEntry: CustomerMapType = {
      classId: '',
      productId: '',
      serviceId: '',
      serviceDescription: '',
      annualRate: '',
      coverage: '',
      customerNbr: '',
      quoteNbr: '',
      serviceType: '',
      deletedAt: '',
    };
    // add the above entry at the top of the array
    setCustomerMapData([newEntry, ...customerMapData]);
  }; */

  /* const handleValueChange = (key: string, value: string) => {
    setNewRecord({ ...newRecord, [key]: value });
  }; */

  /* const handleSave = () => {
    const updatedData = customerMapData.filter((item) => item.classId !== '');
    setCustomerMapData([newRecord, ...updatedData]);
    setNewRecord(null!);
    setEntryCount(entryCount + 1);
    openNotificationWithIcon(
      'success',
      'Success',
      'Customer map entry successfully added'
    );
  }; */

  /* const handleUpdate = (classId: string) => {
    const existingEntry = customerMapData.find(
      (item) => item.classId === updateRecord.classId
    );
    const updatedEntry = { ...existingEntry, ...newRecord };
    const updatedData = customerMapData.filter(
      (item) => item.classId !== updateRecord.classId
    );
    setCustomerMapData([updatedEntry, ...updatedData]);
    setNewRecord(null!);
    setUpdateRecord(null!);
    setEntryCount(entryCount + 1);
    openNotificationWithIcon(
      'success',
      'Success',
      'Customer map entry successfully updated'
    );
  }; */

  /* const handleCancel = () => {
    const updatedData = customerMapData.filter((item) => item.classId !== '');
    setCustomerMapData(updatedData);
    setUpdateRecord(null!);
  }; */

  const handleSyncConfirm = () => {
    setEntryCount(0);
    openNotificationWithIcon(
      'success',
      'Success',
      'Customer map data successfully synced'
    );
  };

  // columns for the table
  const columns = [
    {
      title: 'Class (First 4 digit of Prod ID)',
      dataIndex: 'assetClass',
      key: 'assetClass',
      render: (value: string) => (
        /*  !value || updateRecord?.classId === value ? (
          <Form.Item style={{ margin: '-5px', width: 130 }}>
            <Input
              placeholder="Enter Class"
              defaultValue={value}
              onChange={(e) => handleValueChange('classId', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div>{value}</div>
      ),
      // ),
    },
    {
      title: 'Product ID',
      key: 'productId',
      render: (record: CustomerMapType) => (
        /* !record.productId || updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 150 }}>
            <Input
              placeholder="Enter Product ID"
              defaultValue={record.productId}
              onChange={(e) => handleValueChange('productId', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div>{record.productId}</div>
      ),
      // ),
    },
    {
      title: 'Service ID',
      key: 'serviceId',
      render: (record: CustomerMapType) => (
        /* !record.serviceId || updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 150 }}>
            <Input
              placeholder="Enter Service ID"
              defaultValue={record.serviceId}
              onChange={(e) => handleValueChange('serviceId', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div className="whitespace-nowrap">{record.serviceId.serviceNbr}</div>
      ),
      // ),
    },
    {
      title: 'Service Description',
      key: 'serviceDescription',
      render: (record: CustomerMapType) => (
        /* !record.serviceDescription ||
        updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 200 }}>
            <Input
              placeholder="Enter Service Description"
              defaultValue={record.serviceDescription}
              onChange={(e) =>
                handleValueChange('serviceDescription', e.target.value)
              }
            />
          </Form.Item>
        ) : ( */
        <div>{record.serviceDescription}</div>
      ),
      // ),
    },
    {
      title: 'Annual Rate',
      key: 'annualRate',
      render: (record: CustomerMapType) => (
        /* !record.annualRate || updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 140 }}>
            <Input
              placeholder="Enter Annual Rate"
              defaultValue={record.annualRate}
              onChange={(e) => handleValueChange('annualRate', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div className="text-right">{record.annualRate}</div>
      ),
      // ),
    },
    {
      title: 'Coverage',
      key: 'coverage',
      render: (record: CustomerMapType) => (
        /* !record.coverage || updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 110 }}>
            <Input
              placeholder="Enter Coverage"
              defaultValue={record.coverage}
              onChange={(e) => handleValueChange('coverage', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div className="text-right">{record.coverage}</div>
      ),
      // ),
    },
    {
      title: 'Customer Number',
      key: 'customerNbr',
      render: (record: CustomerMapType) => (
        /* !record.customerNbr || updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 150 }}>
            <Input
              placeholder="Enter Customer Number"
              defaultValue={record.customerNbr}
              onChange={(e) => handleValueChange('customerNbr', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div className="text-right">{record.customerNbr}</div>
      ),
      // ),
    },
    {
      title: 'Quote Number',
      key: 'quoteNbr',
      render: (record: CustomerMapType) => (
        /* !record.quoteNbr || updateRecord?.classId === record.classId ? (
          <Form.Item style={{ margin: '-5px', width: 130 }}>
            <Input
              placeholder="Enter Quote Number"
              defaultValue={record.quoteNbr}
              onChange={(e) => handleValueChange('quoteNbr', e.target.value)}
            />
          </Form.Item>
        ) : ( */
        <div className="text-right">{record.quoteNbr}</div>
      ),
      // ),
    },
    {
      title: 'Service Type',
      key: 'serviceType',
      render: (record: CustomerMapType) => (
        <div className="text-right">{record.serviceId.serviceType}</div>
      ),
    },
    /* {
      title: 'Actions',
      render: (record: CustomerMapType) =>
        !record.classId || updateRecord?.classId === record.classId ? (
          <Space size="middle">
            <Button
              type="primary"
              size="small"
              onClick={
                updateRecord?.classId
                  ? () => handleUpdate(record.classId!)
                  : handleSave
              }
            >
              {updateRecord?.classId ? 'Update' : 'Save'}
            </Button>
            <Button type="text" size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Space size="middle" align="center">
            <Button
              type="text"
              size="small"
              onClick={() => setAuditTrail(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
                <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
                <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
              </svg>
            </Button>
            <Button
              type="text"
              size="small"
              onClick={() => setUpdateRecord(record)}
            >
              <EditFilled />
            </Button>
            <div>
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete this entry?"
                onConfirm={() => confirmDelete(record.classId!)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" size="small">
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      key: 'actions',
    }, */
  ];

  const clearFilters = () => {
    tableRefresh();
    setSearchField('Select Field');
    setSearchValue('');
    setFilters('');
  };

  return (
    <>
      <Row className="h-full">
        <Col span={24}>
          <Card
            title="Customer Map"
            className="h-full"
            extra={
              <CardActions
                filteredData={customerMap?.data}
                /* handleAdd={handleAdd}
                entryCount={entryCount}
                handleSyncConfirm={handleSyncConfirm} */
              />
            }
          >
            <DataFilters
              setFilters={setFilters}
              tableRefresh={tableRefresh}
              clearFilters={clearFilters}
            />
            <div className="search-bar">
              <Search
                addonBefore={selectBefore}
                placeholder="Search for Class, Product ID, Service ID, Coverage, Customer Number, Quote Number, Service Type"
                size="large"
                enterButton="Search"
                disabled={searchField === 'Select Field'}
                onSearch={(e) => handleSearch(e)}
                value={searchValue}
                prefix={
                  searchField !== 'Select Field' && (
                    <ClearFilter clearFilters={clearFilters} />
                  )
                }
                onChange={(e) => setSearchValue(e.target.value)}
                /* onSearch={(e) => handleSearch(e)}
                onChange={(e) => handleClear(e.target.value)} */
              />
            </div>
            <br />
            <Table
              columns={columns}
              rowKey={(record) => record.id!}
              dataSource={customerMap?.data}
              loading={isLoading}
              onChange={handleChange}
              locale={{
                emptyText: <Empty description="No customer map data" />,
              }}
              className="customer-map-table"
              pagination={{
                defaultPageSize: 10,
                current: page + 1,
                pageSize,
                total: Number(customerMap?.headers['x-total-count']),
                pageSizeOptions: ['10', '20', '50', '100'],
                showSizeChanger: true,
                hideOnSinglePage:
                  Number(customerMap?.headers['x-total-count']) <= pageSize,
              }}
            />
          </Card>
        </Col>
      </Row>
      <AuditTrailModal
        auditTrail={auditTrail}
        setAuditTrail={setAuditTrail}
        searchFields={[
          { id: 'classId', value: 'Class (First 4 digit of Prod ID)' },
          { id: 'productId', value: 'Product ID' },
          { id: 'serviceId', value: 'Service ID' },
          { id: 'coverage', value: 'Coverage' },
          { id: 'customerNbr', value: 'Customer Number' },
          { id: 'quoteNbr', value: 'Quote Number' },
          { id: 'executionStatus', value: 'Execution Status' },
          { id: 'uploadedBy', value: 'Uploaded By' },
          { id: 'uploadedAt', value: 'Uploaded At' },
        ]}
      />
    </>
  );
};

export default CustomerMapTable;
