import React, { useEffect } from 'react';

import { Button, Space } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

type DataFiltersProps = {
  setFilters: React.Dispatch<React.SetStateAction<string>>;
  tableRefresh: () => void;
  clearFilters: () => void;
};

const DataFilters = ({
  setFilters,
  tableRefresh,
  clearFilters,
}: DataFiltersProps) => {
  const [active, setActive] = React.useState(true);

  const handleActive = () => {
    tableRefresh();
    clearFilters();
    setFilters('isdeleted=false');
    setActive(true);
  };

  const handleInActive = () => {
    tableRefresh();
    clearFilters();
    setFilters('isdeleted=true');
    setActive(false);
  };

  return (
    <Space
      size="middle"
      align="baseline"
      className="pb-4 w-full flex justify-end "
    >
      <ButtonGroup>
        <Button
          type={active ? 'primary' : 'dashed'}
          size="large"
          onClick={() => handleActive()}
          shape="round"
        >
          Active
        </Button>
        <Button
          type={!active ? 'primary' : 'dashed'}
          size="large"
          onClick={() => handleInActive()}
          className="rounded-md"
          shape="round"
        >
          Inactive
        </Button>
      </ButtonGroup>
    </Space>
  );
};

export default DataFilters;
