export type AuditTrailType = {
  classId: string;
  productId: string;
  serviceId: string;
  serviceDescription: string;
  annualRate: string;
  coverage: string;
  customerNbr: string;
  quoteNbr: string;
  executionStatus: string;
  uploadedBy: string;
  uploadedAt: string;
  executionSummary: string;
  [key: string]: string;
};
const auditTrailMap: AuditTrailType[] = [
  {
    classId: '1234',
    productId: '1234-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '1899.96',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary:
      'Records Updated, Field Changed, Annual Rate 1522.24 -> 1899.96',
  },
  {
    classId: '1111',
    productId: '1111-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '150.11',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Skipped',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary: 'Duplicate Record',
  },
  {
    classId: '2222',
    productId: '2222-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '200.34',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary: 'New Record Added',
  },
  {
    classId: '3333',
    productId: '3333-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '540',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Skipped',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary: 'Duplicate Record',
  },
  {
    classId: '4444',
    productId: '4444-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '640',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary: 'New Record Added',
  },
  {
    classId: '5555',
    productId: '5555-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '200',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary:
      'Records Updated, Field Changed, Annual Rate 1522.24 -> 200',
  },
  {
    classId: '6666',
    productId: '6666-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '287.2',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary:
      'Records Updated, Field Changed, Annual Rate 1522.24 -> 287.2',
  },
  {
    classId: '7777',
    productId: '7777-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '250.2',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Skipped',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary: 'Duplicate Record',
  },
  {
    classId: '8888',
    productId: '8888-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '123',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary:
      'Records Updated, Field Changed, Annual Rate 1522.24 -> 123',
  },
  {
    classId: '9999',
    productId: '9999-5678-9876',
    serviceId: '1234-SERV-0001',
    serviceDescription: 'SLM Preferred Offers',
    annualRate: '300.2',
    coverage: '123456ES',
    customerNbr: '123',
    quoteNbr: '10-124111',
    executionStatus: 'Success',
    uploadedBy: 'Admin',
    uploadedAt: '2021-10-01',
    executionSummary: 'New Record Added',
  },
];

export default auditTrailMap;
